<template>
  <div class="card-box">
    <slot />
  </div>
</template>

<style scoped>
.card-box {
  @apply w-full flex flex-col px-6 border rounded-t-lg rounded-b-xl shadow bg-secondary-950 border-secondary-900;
}
</style>